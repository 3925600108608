<template>
  <div class="login">
    <!-- <div class="login_content">
      <h4>微信登录</h4>
      <img :src="codeUrl" alt="" />
      <p>请使用微信扫描二维码登录</p>
    </div> -->
    <div class="login_tips">
      <h4>登录</h4>
      <p @click="toContinue()">继续登录</p>
      <p @click="checkBack()">返回上一页</p>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "@/utils/api.js";

export default {
  data() {
    return {
      codeUrl: "",
    };
  },
  mounted() {
    const token = this.$route.query.token;
    // console.log("》》》 ",window.localStorage.getItem("linkurl"));
    if (token) {
      // this.global.year = "2024sz";
      window.localStorage.setItem("token", token);
      // if(window.localStorage.getItem("linkurl")) {
      //   let h = window.localStorage.getItem("linkurl");
      //   window.localStorage.removeItem("linkurl");
      //   this.$router.push( h );
      // } else {
      //   this.$router.push({ path: `/${this.global.year}/home` })
      // }
      this.checkBack(token);
    } else {
      this.memberLogin(); // 跳转member
    }
  },
  methods: {
    // 跳转member进行登录(新登录方式[2021-11])
    memberLogin() {
      const token = window.localStorage.getItem("token", token);
      if (token === undefined || !token) {
        let type = 'giac'; // 'a2m'
        let href = `https://member.msup.com.cn/login?type=${type}&url=${encodeURIComponent(
          window.location.href
        )}`;
        window.location.href = href;
      } else {
        this.checkBack(token);
      }
    },
    // 回调判断([2024-04])
    toContinue() {
      this.memberLogin();
    },
    checkBack(token) {
      if(window.localStorage.getItem('linkurl')) {
        let h = window.localStorage.getItem('linkurl');
        // 检查上一页是否是必须登录/需要先完善信息的页面
        let needLogin = ['/order', '/userCenter', '/live'];
        let needInfo = ['/order', '/live'];
        let lFlag=false, iFlag=false; // 需要登录，需要完善
        for(let path of needLogin) {
          if(h.includes(path)) {
            lFlag = true;
            break;
          }
        }
        for(let path of needInfo) {
          if(h.includes(path)) {
            iFlag = true;
            break;
          }
        }
        // console.log(lFlag, iFlag);
        // return;
        // 需要完善信息->检查信息->完整则回跳,否则转至个人中心设置
        if(iFlag && token) {
          this.$http
            .jsonp(`${getUserInfo}?token=${token}`)
            .then((data) => {
              return data.json();
            })
            .then((res) => {
              // console.log("header- checkUserInfo",res);
              if (res.status === '200') {
                let flag = true;
                for (const key in res.data) {
                  if (!res.data[key] || res.data[key]==='') {
                    flag = false;
                    if (window.confirm('需要您先完善个人信息')) {
                      localStorage.setItem('link_before_complete', h);
                      this.$router.push({ path: `/${this.global.year}/userCenter`, query: { selected: '2' } });
                    } else {
                      window.localStorage.removeItem('linkurl');
                      this.$router.push({ path: `/${this.global.year}/home` });
                    }
                    break;
                  }
                }
                if (flag) {
                  window.localStorage.removeItem('linkurl');
                  this.$router.push( h );
                }
              } else {
                this.$toast('登录异常');
                setTimeout(() => {
                  window.localStorage.removeItem('token');
                  window.localStorage.removeItem('linkurl');
                  this.$router.push({ path: `/${this.global.year}/home` });
                }, 300);
              }
            })
        } else {
          // 需要登录->回跳/跳转首页
          if(!lFlag || (lFlag && token)) {
            window.localStorage.removeItem('linkurl');
            this.$router.push( h );
          } else {
            window.localStorage.removeItem('linkurl');
            this.$router.push({ path: `/${this.global.year}/home` });
          }
        }
      } else {
        this.$router.push({ path: `/${this.global.year}/home` });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// @media (min-width: 767px) {
//   .login {
//     margin-top: 150px;
//   }
// }
// @media (max-width: 767px) {
//   .login {
//     margin-top: 2rem;
//   }
// }
.login {
  width: 100%;
  height: calc(100vh - 150px);
  padding: 50px;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  .login_content {
    text-align: center;
    h4 {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
    img {
      width: 280px;
      margin-top: 15px;
      border: 1px solid #e2e2e2;
    }
    p {
      margin-top: 15px;
      margin-left: auto;
      margin-right: auto;
      background-color: #232323;
      border-radius: 100px;
      -moz-border-radius: 100px;
      -webkit-border-radius: 100px;
      box-shadow: inset 0 5px 10px -5px #191919, 0 1px 0 0 #444;
      -moz-box-shadow: inset 0 5px 10px -5px #191919, 0 1px 0 0 #444;
      width: 280px;
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 13px;
      line-height: 30px;
      font-weight: bold;
      color: #fff;
    }
  }
  .login_tips {
    text-align: center;
    h4 {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    p {
      margin-top: 15px;
      margin-left: auto;
      margin-right: auto;
      background-color: #033077;
      border-radius: 100px;
      -moz-border-radius: 100px;
      -webkit-border-radius: 100px;
      box-shadow: inset 0 5px 10px -5px #191919, 0 1px 0 0 #444;
      -moz-box-shadow: inset 0 5px 10px -5px #191919, 0 1px 0 0 #444;
      width: 280px;
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 14px;
      line-height: 30px;
      font-weight: bold;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>
